import * as React from "react";
import {
  CogIcon,
  CubeTransparentIcon,
  ServerIcon,
} from "@heroicons/react/solid";
import { InboxIcon } from "@heroicons/react/outline";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import ActionCallSection from "../components/sections/ActionCallSection";
import ServicesSection from "../components/sections/ServicesSection";
import ServiceItem from "../components/elements/ServiceItem";
import Footer from "../components/sections/Footer";
import AboutModelsSection from "../components/sections/AboutModelsSection";
import AboutModelItem from "../components/elements/AboutModelItem";
import FeaturesSection from "../components/sections/FeaturesSection";
import shop from "../images/crforweb.jpg";
import douglas from "../images/douglas.jpg";
import GoToSection from "../components/sections/GoToSection";

const IndexEnPage = () => (
  <div className="bg-white">
    <PageTitle pageTitle={"Home"} lang={"en"} description={""} />
    <Header lang={"en"} />
    <ActionCallSection
      mainTitle={"posplus"}
      highlightedText={"control unit"}
      description={
        "Despite the compact format, Posplus is both the market’s most powerful and most compatible control unit."
      }
      brightName={
        "WELCOME TO POSPLUS – MARKET LEADING CONTROL UNIT FOR FULL CHECK AT THE CHECKOUT"
      }
    />
    <FeaturesSection
      FirstIcon={InboxIcon}
      firstTitle={`The next generation of control units`}
      firstDescription={`We implemented a NEW WAY to process fiscal data, the latest generation of hardware that signs and stores fiscal data and REPLICATES DATA to the server and can be connected to the local network and support MULTIPLE cash registers`}
      firstImgName={shop}
      firstBtnText={`Contact us`}
      firstBtnPath="/en/contacts"
      firstDetailsText={`No more need for the Tax Agency to visit your point of sale to download fiscal data`}
      firstDetailsImgName={douglas}
      firstDetailsAuthor={`Douglas Oest, Axena Company Service AB, CEO`}
      secondBtnText={`Read more...`}
      secondBtnPath="/en/models/buffering-unit"
      /*SecondIcon={SparklesIcon}
      // secondTitle - look at FeaturesSection
      secondDescription={`Fiscal data is being sent to the server in REAL TIME. All fiscal data is replicated and kept on BOTH SIDES: Buffering Unit and Control Server. 
      
      If the internet connection is lost, the Buffering Unit continues signing and buffers fiscal data for 48 hours, waits until the internet connection is restored, then sends data to the Control Server
`}
      secondImgName={schema}
      thirdBtnText={`Contact us`}
      thirdBtnPath="/en/contacts"
    */
    />
    <ServicesSection
      sectionTitle={"Technology becomes smaller"}
      title={""}
      description={`Even the technical part is getting bigger, now there are our control units with storage from 32,000,000+ records with memories from 8Gb up to 128Gb capacity.`}
    >
      <ServiceItem
        name="Smooth. Multicompatible"
        description={`PosPlus can be easily adapted and integrated with your existing cash solutions. No external power source is needed and the compact format is perfect for mobile solutions.`}
        IconImg={CubeTransparentIcon}
      />
      <ServiceItem
        name="Powerful. Unmatched capacity"
        description={`PosPlus stores from 32 million + receipts and can register a new organization number / cash page 1000 times and order for up to 9000 users. Outdoors, it can withstand temperatures down to -20C. This means that PosPlus can handle the most demanding environments and needs by a good margin.`}
        IconImg={CogIcon}
        showMorePath={"/en/description"}
      />
      <ServiceItem
        name="Safe. Double certified"
        description={`PosPlus is certified and approved in accordance with the Swedish Tax Agency’s regulations. In addition, PosPlus is one of the few control units that also qualifies for the industry’s own certification Kassacertifiering.se`}
        IconImg={ServerIcon}
        showMorePath={"/en/support"}
      />
    </ServicesSection>
    <AboutModelsSection
      sectionTitle="Models"
      title="Choose Control Unit up to your business"
      description="From MVP development to post-release support, we help our customers bring their products to market faster and with no barriers on the way."
    >
      <AboutModelItem
        name="Model A"
        description={`Model A is intended for a cash register. Connected via the checkout’s USB port.`}
        linkText="Read more"
        linkPath="/en/models/a"
      />
      <AboutModelItem
        name="Model C"
        description={`Model C is intended to be connected to a central server, locally or via the Internet and thus function as a control unit for a number of cash registers.`}
        linkText="Read more"
        linkPath="/en/models/c"
      />
      <AboutModelItem
        name="Buffering Unit"
        description={`Fiscal data is being sent to the server in realtime. All fiscal data is replicated and kept on the both sides: Buffering Unit and Control Server.`}
        linkText="Read more"
        linkPath="/en/models/buffering-unit"
      />
    </AboutModelsSection>
    <GoToSection
      title="Cloud Control Units"
      description="Try the new way to manage your fiscal data - just send it to the cloud server. Click below to know more"
      btnPath="https://srv4pos.com/"
      btnText="Go to srv4pos.com"
    />

    <Footer lang={"en"} />
  </div>
);

export default IndexEnPage;
